import { FC } from 'react';
import { HomepageFragment } from '../../../generated/types';
import { ParagraphRenderer } from '../../Paragraph/ParagraphRenderer';
import { Metatags } from '../../Layout/Metatags';

interface HomepageProps {
  data: HomepageFragment;
}

export const Homepage: FC<HomepageProps> = ({ data }) => {
  return (
    <>
      <Metatags metatags={data.metatags} />
      <ParagraphRenderer
        paragraphs={data.content}
        wrapperProps={{
          py: 0,
          __css: {
            '&.ParagraphSectionWithTextImage + .ParagraphSectionWithTextImage':
              {
                pt: 6,
              },
          },
        }}
      />
    </>
  );
};

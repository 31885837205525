import Head from 'next/head';
import { createElement, FC, useContext, useMemo } from 'react';
import { MetatagsFragment } from '../../generated/types';
import { PUBLIC_RUNTIME_CONFIG } from '../../config/constants';
import { PageContext } from '../../context/PageContext';

interface MetatagsProps {
  metatags?: MetatagsFragment[];
}

export const Metatags: FC<MetatagsProps> = ({ metatags }) => {
  if (!metatags || !metatags.length) return null;
  const pageContext = useContext(PageContext);
  const publicRuntimeConfig = PUBLIC_RUNTIME_CONFIG;

  const parsedMetatags = useMemo(() => {
    const title = metatags.find((tag) => tag.tag === 'title');

    const tags = metatags
      .filter((tag) => tag.tag !== 'title')
      .map((tag) => {
        return createElement(tag.tag, {
          key: JSON.stringify(tag),
          name: tag.attributes.name || undefined,
          content: tag.attributes.content || undefined,
          rel: tag.attributes.rel || undefined,
          href: tag.attributes.href || undefined,
          property: tag.attributes.property || undefined,
        });
      });

    return {
      title: title?.attributes.content || '',
      tags,
    };
  }, [metatags]);

  let canonicalUrl = undefined;

  if (pageContext.entity) {
    canonicalUrl = `${publicRuntimeConfig.FRONTEND_URL}${pageContext.entity.url}`;

    if (pageContext.entity.url === '/home') {
      canonicalUrl = publicRuntimeConfig.FRONTEND_URL;
    }
  }


  return (
    <Head>
      <title>{parsedMetatags.title}</title>

        <link
          rel="canonical"
          href={canonicalUrl}
        />
      {parsedMetatags.tags}
    </Head>
  );
};
